import { createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = {
  xmlns: "http://www.w3.org/2000/svg",
  width: "108",
  height: "108",
  fill: "none"
}

export function render(_ctx, _cache) {
  return (_openBlock(), _createElementBlock("svg", _hoisted_1, _cache[0] || (_cache[0] = [
    _createElementVNode("circle", {
      cx: "54",
      cy: "54",
      r: "54",
      fill: "#010101"
    }, null, -1),
    _createElementVNode("path", {
      fill: "#FFE217",
      d: "m53.73 55.355-2.056-18.203H39.008l-6.176 34.56h9.435l2.582-14.437 1.845 14.437h8.444l6.975-14.236-2.562 14.236h9.434l6.183-34.56H62.154z"
    }, null, -1)
  ])))
}
export default { render: render }